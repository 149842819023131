import React, { useState, useEffect } from 'react';
import { FaRegClipboard } from 'react-icons/fa';
import wordsFile from './english.txt';
import { Helmet } from 'react-helmet-async';

function Home() {
  const [wordList, setWordList] = useState([]);
  const [selectedWords, setSelectedWords] = useState([]);
  const [drawingIndices, setDrawingIndices] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const response = await fetch(wordsFile);
        const text = await response.text();
        const words = text.split('\n').map(word => word.trim()).filter(word => word.length > 0);
        setWordList(words);
        
        // Automatically pick 120 words on first load
        const shuffled = words.sort(() => 0.5 - Math.random());
        const selected = shuffled.slice(0, 120);
        setSelectedWords(selected);
      } catch (error) {
        console.error('Error fetching the word list:', error);
      }
    };

    fetchWords();
  }, []);

  const regenerateWords = () => {
    const shuffled = wordList.sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, 120);
    setSelectedWords(selected);
    setDrawingIndices([]); // Reset drawing indices
  };

  const redraw = () => {
    setDrawingIndices([]); // Clear all hidden indices to allow redrawing
  };

  const handleMouseDown = (index) => {
    setIsDrawing(true);
    addDrawingIndex(index);
  };

  const handleMouseOver = (index) => {
    if (isDrawing && drawingIndices.length < 24) {
      addDrawingIndex(index);
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const addDrawingIndex = (index) => {
    if (!drawingIndices.includes(index) && drawingIndices.length < 24) {
      setDrawingIndices((prev) => [...prev, index]);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert(`Copied to Clipboard: ${text}`);
    });
  };

  return (
    <div className="App">
      <Helmet>
        <title>Crypto Store - Store your recovery seeds smart</title>
        <meta name="description" content="Explore our Bitcoin and crypto store. Store your recovery seeds smart with BIP39." />
        <meta name="keywords" content="bitcoin, crypto store, BIP39, recovery seeds" />
      </Helmet>
      <main className="App-main">
        <div className="content-container">
          <div
            className="word-grid"
            onMouseLeave={() => setIsDrawing(false)} // Stop drawing if mouse leaves the grid
          >
            {selectedWords.map((word, index) => (
              <div
                key={index}
                className={`word-cell ${
                  drawingIndices.includes(index) ? 'hidden-cell' : ''
                }`}
                onMouseDown={() => handleMouseDown(index)}
                onMouseOver={() => handleMouseOver(index)}
                onMouseUp={handleMouseUp}
              >
                {drawingIndices.includes(index) 
                  ? drawingIndices.indexOf(index) + 1 // Show the number corresponding to the position
                  : word}
              </div>
            ))}
          </div>
        </div>
        <div className="button-group">
          <button onClick={regenerateWords} disabled={wordList.length === 0}>
            {wordList.length === 0 ? 'Loading words...' : 'Regenerate Words'}
          </button>
          <button onClick={redraw} disabled={wordList.length === 0}>
            Redraw Pattern
          </button>
        </div>
      </main>
    </div>
  );
}

export default Home;